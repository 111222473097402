@font-face {
  font-family: "CiscoSans";
  src: local("CiscoSans"),
  url("./fonts/CiscoSans/CiscoSans.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CiscoSansBold";
  src: local("CiscoSansBold"),
  url("./fonts/CiscoSans/CiscoSansBold.ttf") format("truetype");
  font-weight: bold;
}

body {
  background: url("./assets/img/tela_webex_4k.png") no-repeat center center fixed;
  background-size: cover;
}
